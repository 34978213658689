
export const updateCommentTooltip = (html) => {
  const $entryComment = html.find('.entry-comment')
  if (!$entryComment?.length) { return }
  const $entryCommentInput = $entryComment.find('input')
  if (!$entryCommentInput?.length) { return }

  $entryComment.attr('data-tooltip', $entryCommentInput.val())
  $entryCommentInput.on('change', function (event) {
    if ($entryComment[0]?._tippy?.destroy) { $entryComment[0]?._tippy.destroy() }

    $entryComment.attr('data-tooltip', event.target.value)
    Utils.updateTooltipsInstances(event.target.parent)
  })
}

import { NotFoundError } from '../../../modules/utils/errors'
import { flatObject } from '@/helpers/object_helpers'

FormFactory.core = function (params) {
  const EDIT = 1
  const NEW = 2

  let mode = false
  const state = {}
  const submitQueue = []

  // check for correct module initialization
  if (!params.form.length) throw new Error('[form] not passed')

  // set variables
  const form = params.form

  // set external
  const remoteFormModel = params.remoteFormModel || params.model

  form.remote_form($.extend({
    model: remoteFormModel,
    onSuccess: _successSubmit,
    onError: _errorSubmit,
  }, params.remoteFormParams))

  const parseErrors = function (data) {
    const result = {}

    Object.keys(data || {}).forEach(function (errorPointer) {
      let pointer = result
      const errorFieldParts = errorPointer.split('.')
      const errorsArray = data[errorPointer].slice()
      const errorIndex = errorFieldParts.length - 1

      errorFieldParts.forEach(function (errorFieldPart, index) {
        if (/\[\d+]/.test(errorFieldPart)) {
          const name = errorFieldPart.match(/\w+/)[0] + '_attributes'
          const number = errorFieldPart.match(/\d+/)[0]
          if (!pointer[name]) pointer[name] = {}
          if (!pointer[name][number]) pointer[name][number] = {}
          if (index === errorIndex) pointer[name][number] = errorsArray
          pointer = pointer[name][number]
        } else {
          if (!pointer[errorFieldPart]) pointer[errorFieldPart] = {}
          if (index === errorIndex) pointer[errorFieldPart] = errorsArray
          pointer = pointer[errorFieldPart]
        }
      })
    })

    return result
  }

  function _errorSubmit (data) {
    if (data && data.notificator_hack) {
      Notificator.error(data.notificator_hack.error_message)

      return
    }

    if (params.notificator_hack) {
      if (data && data.base) {
        Utils.reportError('core:_errorSubmit', data.base[0])()
      } else {
        Notificator.error(I18n.t('form_error'))
        console.error(I18n.t('form_error'), data)
      }
    }

    const queueLengh = submitQueue.length
    switch (mode) {
      case EDIT:
        PubSub.publish('page.form.' + params.model + '.submitError', {
          data,
          errors: parseErrors(flatObject(data)),
          original: state.originalItem,
          queueLength: queueLengh,
        })
        break
      case NEW:
        PubSub.publish('page.form.' + params.model + '.submitError', {
          data,
          errors: parseErrors(flatObject(data)),
          queueLength: queueLengh,
        })
        break
    }
  }

  function _successSubmit (item, unblcok) {
    const queueLengh = submitQueue.length
    switch (mode) {
      case EDIT:
        PubSub.publish('page.form.' + params.model + '.submitSuccess', {
          item,
          original: state.originalItem,
          queueLength: queueLengh,
          fnUnlock: unblcok,
          mode: 'edit',
        })
        break
      case NEW:
        PubSub.publish('page.form.' + params.model + '.submitSuccess', {
          item,
          queueLength: queueLengh,
          fnUnlock: unblcok,
          mode: 'new',
        })
        break
    }

    if (queueLengh) {
      submitQueue.shift()(item)
    }

    storeOriginalSerialized()
    // update state for original after succes submit and notify all components
    state.originalItem = item

    if (!queueLengh && $.isFunction(params.onSubmit)) {
      params.onSubmit(item)
    }
  }

  function checkForChanges (msg, fn) {
    const orig = JSON.stringify(state.originalItemSerialized)
    const current = JSON.stringify(form.remote_form('serialize'))

    fn(orig !== current)
  }

  function askReset () {
    reset()
  }

  function askClear () {
    clear()
  }

  function askNew (e, data) {
    newItem(data.proto, data.useClear)
  }

  function askEdit (e, data) {
    editItem(data.item)
  }

  function storeOriginalSerialized () {
    setTimeout(function () {
      state.originalItemSerialized = form.remote_form('serialize')
    }, 500)
  }

  /**
   * Shows edit form for an item.
   * Returns promise if promise is passed into it,
   * otherwise returns
   *
   * @param {Object|Promise<any>} item
   * @return {Object|Promise<any>}
   */
  function editItem (item) {
    if (Utils.isPromise(item)) {
      // not sure about this, only decorative
      form.remote_form('clear')
      PubSub.publish('page.form.' + params.model + '.fetching', {
        promise: item,
      })

      return item.then((item) => {
        if (item) {
          PubSub.publish('page.form.' + params.model + '.fetched', {
            item,
          })
          editItem(item)
        } else {
          // if promise resolved without an item, it probably was not found
          // while trying to get it by id, so just close the loader
          // and the the modal itself
          PubSub.publish('page.form.' + params.model + '.fetched')
          PubSub.publish('page.form.' + params.model + '.modal.close')

          throw new NotFoundError()
        }
      })
    }

    mode = EDIT

    // if passed item, set form to this item,
    // else get original item from serialization
    if (item) {
      form.remote_form('clear')
      form.remote_form('update', item)
      storeOriginalSerialized()
    } else {
      item = form.remote_form('serialize')
      storeOriginalSerialized()
    }

    state.originalItem = item

    PubSub.publish('page.form.' + params.model + '.setEdit', {
      item,
    })
  }

  // FIXME: clear instad of reset in new?
  /**
   *
   * @param {Promise<object> | object} proto Объект-источник данных
   * @param {boolean} useClear Метод очищения формы - clear; если false, то reset
   * @return {Promise<any> | undefined}
   */
  function newItem (proto, useClear) {
    mode = NEW
    if (Utils.isPromise(proto)) {
      PubSub.publish('page.form.' + params.model + '.fetching')

      return proto.then((item) => {
        if (item) {
          PubSub.publish('page.form.' + params.model + '.fetched')
          newItem(item, useClear)
        } else {
          // if promise resolved without an item, it probably was not found
          // while trying to get it by id, so just close the loader
          // and the the modal itself
          PubSub.publish('page.form.' + params.model + '.fetched')
          PubSub.publish('page.form.' + params.model + '.modal.close')

          throw new NotFoundError()
        }
      })
    }

    if (useClear === true) {
      form.remote_form('clear')
    } else {
      form.remote_form('reset')
    }
    if (typeof proto === 'object') form.remote_form('update', proto)

    PubSub.publish('page.form.' + params.model + '.setNew', {
      item: proto,
    })
    storeOriginalSerialized()
    // wait a little for mass initialization and serialize
  }

  function reset () {
    form.remote_form('reset')

    PubSub.publish('page.form.' + params.model + '.reset', {
      // item: proto
    })
  }

  function clear () {
    form.remote_form('clear')

    PubSub.publish('page.form.' + params.model + '.clear', {
      // item: proto
    })
  }

  function submit () {
    form.submit()
  }

  function updateStoredValues () {
    form.remote_form('updateStoredValues')
  }

  function getSubmitQueue () {
    return submitQueue.slice()
  }

  function addToSubmitQueue (msg, fn) {
    submitQueue.push(fn)
  }

  const silentUpdate = function (opts, cb) {
    if (!$.isFunction(cb)) cb = _.noop

    const data = {}
    data[remoteFormModel] = opts.item

    $.ajax({
      url: params.fnItemPath(opts.item.id),
      data,
      method: 'PATCH',
      success (updatedItem) {
        cb({
          item: updatedItem,
        }, false)
      },
      error (data) {
        cb({
          item: opts.item,
          error: data.responseJSON,
        }, true)
      },
    })
  }

  PubSub.subscribe('page.form.' + params.model + '.addToSubmitQueue', addToSubmitQueue)
  PubSub.subscribe('page.form.' + params.model + '.checkForChanges', checkForChanges)

  PubSub.subscribe('page.form.' + params.model + '.askReset', askReset)
  PubSub.subscribe('page.form.' + params.model + '.askClear', askClear)

  PubSub.subscribe('page.form.' + params.model + '.askNew', askNew)
  PubSub.subscribe('page.form.' + params.model + '.askEdit', askEdit)

  PubSub.subscribe('page.form.' + params.model + '.askSubmit', submit)
  PubSub.subscribe('page.form.' + params.model + '.askUpdateStoredValues', updateStoredValues)

  const subscribe = (msg, cb) => {
    PubSub.subscribe(`page.form.${params.model}.${msg}`, cb)
  }

  return {
    silentUpdate,
    editItem,
    newItem,
    reset,
    clear,
    submit,
    updateStoredValues,
    getSubmitQueue,
    addToSubmitQueue,
    subscribe,
  }
}

import { calculateEntryTotals } from '@/forms/components/entry/_helpers'
import { cloneDeep } from 'lodash'
import { drawEntryMeasureUnits } from '@/vue_apps/MeasureUnits/logic/drawEntryMeasureUnit'

FormFactory.entryLists = function (params) {
  const ENTRY_NO_STATE = 3
  const ENTRY_NOT_READY = 1

  const ANALYSIS = 1
  const SERVICE = 4
  const COMPLEX = 6
  const isComplex = gon.specific.entry.kind === COMPLEX

  const msgPrefix = 'page.form.' + params.model + '.entryLists.'

  let attributes = { amount: params.entryLists.initialAmount }
  const memberTemplate = $('._template_members')
  const consumableTemplate = $('._template_consumables')
  const round = Utils.moneyRound

  const tables = $('#entry-tables')
  const memberList = $('#members_list')
  const consumableList = $('#consumables_list')

  let memberSchema = {}
  let consumableSchema = {}

  const memberSelects = []
  const complexStateSelect = $('#entry_state')

  //
  // core funcs
  //

  const addNewMemberStateSelect = (select) => {
    memberSelects.push(select)

    select.on('change', (e, params) => {
      if (params && params.fromComplex) return

      const currentState = select.val()
      const everyMemberHasSameState = memberSelects.every((ms) => ms.val() === currentState)

      const newComplexState = everyMemberHasSameState ? currentState : ENTRY_NOT_READY
      const oldComplexState = complexStateSelect.val()

      if (parseInt(oldComplexState) !== parseInt(newComplexState)) {
        complexStateSelect.val(newComplexState)
        complexStateSelect.trigger('change', { fromMember: true })
      }
    })
  }

  if (isComplex && gon.specific.entry_have_status) {
    const initialMemberSelects = $('.entry_members_state select')
    initialMemberSelects
      .toArray()
      .forEach((s) => addNewMemberStateSelect($(s)))

    complexStateSelect.on('change', (e, params) => {
      if (params && params.fromMember) return

      memberSelects.forEach((e) => {
        e.val(complexStateSelect.val())
        e.trigger('change', { fromComplex: true })
      })
    })
  }

  const nestedSelector = function (container, selector) {
    return function () {
      return container.find(selector).toArray().map(function (e) {
        return $(e).nestedFieldSet()
      })
    }
  }

  const getMembers = nestedSelector(memberList, '.f-entry-nested-member')
  const getConsumables = nestedSelector(consumableList, '.f-entry-nested-consumable')

  const updateSchema = function () {
    const updater = function (collection, schema) {
      collection.forEach(function (item) {
        schema[item.getIndex()] = parseInt(item.get('amount')) / attributes.amount
      })
    }

    memberSchema = {}
    consumableSchema = {}
    updater(getMembers(), memberSchema)
    updater(getConsumables(), consumableSchema)
  }

  const postRenderRootElement = function (element) {
    if (attributes.amount > 1) {
      element.lockFields('amount')
    } else {
      if (!params.lockedFromBillingChanges) element.unlockFields('amount')
    }

    element.updateContentItems()
  }

  const updateList = function () {
    const redraw = function (list, schema) {
      list.forEach(function (elem) {
        // по каким-то причинам может придти пустая схема и тогда schema[elem.getIndex()] будет undefined
        // дальше amount * undefined === NaN
        const amount = attributes.amount * (schema[elem.getIndex()] || 1)
        const sum = elem.get('price', 'float') * amount
        const finalSum = elem.get('final_price', 'float') * amount

        const elemData = {
          amount,
          sum,
          final_sum: finalSum,
          discount_sum: round(sum - finalSum),
        }

        elem.set(elemData)
        postRenderRootElement(elem)
      })
    }

    redraw(getMembers(), memberSchema)
    redraw(getConsumables(), consumableSchema)

    triggerListChanged()
  }

  const init = function () {
    updateSchema()

    getMembers().forEach(function (row) {
      row.init()
      row.hideContent()
      if (params.lockedFromBillingChanges) row.lockFromBillingChanges()
    })

    getConsumables().forEach(function (row) {
      row.init()
    })
  }

  let updateIndexesTimeout
  const updateIndexes = function () {
    if (updateIndexesTimeout) clearTimeout(updateIndexesTimeout)

    const reindex = function (row, i) {
      row.setIndex(i)
      row.init()
    }

    updateIndexesTimeout = setTimeout(function () {
      getMembers().forEach(reindex)
      getConsumables().forEach(reindex)
    }, 42)
  }

  const buildItem = function (proto) {
    return {
      amount: proto.amount,
      entry_type_id: proto.id,
      title: proto.title,
      number: proto.number,
      price: proto.price,
      final_price: proto.price,
      cost_price: proto.cost_price,
      kind: proto.kind,
      state: ENTRY_NO_STATE,
      clinic_id: attributes.clinic_id,
      client_id: attributes.client_id,
      user_id: attributes.user_id,
      assistant_id: attributes.assistant_id,
      store_id: attributes.store_id,
      discount_disabled: proto.discount_disabled,
      date: attributes.date || Utils.getBaseFormattedDate(new Date()),
      measure_unit: proto.measure_unit,
    }
  }

  let triggerListChangedDelay = null
  var triggerListChanged = function () {
    if (triggerListChangedDelay) clearTimeout(triggerListChangedDelay)

    triggerListChangedDelay = setTimeout(function () {
      const totals = {
        sum: 0,
        final_sum: 0,
        price: 0,
        final_price: 0,
      }
      getMembers().forEach(function (row) {
        if (row.isDeleted()) return

        const amount = row.get('amount', 'int')
        const price = row.get('price', 'float') * amount
        const finalPrice = row.get('final_price', 'float') * amount

        totals.sum += price
        totals.price += price
        totals.final_sum += finalPrice
        totals.final_price += finalPrice
      })

      totals.sum = round(totals.sum)
      totals.final_sum = round(totals.final_sum)
      totals.discount_sum = round(totals.sum - totals.final_sum)

      PubSub.emit('page.form.' + params.model + '.updateComponents')
      PubSub.emit(msgPrefix + 'listChanged', totals)
    }, 10)
  }

  // remove passed attributes & undefineds
  const cleanAttributes = function (attrs) {
    const omitList = Array.prototype.slice.call(arguments, 1)
    attrs = _.omit.apply(_, [attrs].concat(omitList))

    return _.omit(attrs, _.isUndefined)
  }

  const entryHasStatus = function (entry) {
    if (entry.kind === ANALYSIS) return gon.specific.entry_analysis_status
    if (entry.kind === SERVICE) return gon.specific.entry_status

    return false
  }

  const addMember = function (entryType, entry) {
    const item = buildItem(entryType)

    const memberAmount = attributes.amount // FIXME: use entry value (but why)

    if ([ANALYSIS, SERVICE].includes(item.kind)) {
      item.state = ENTRY_NOT_READY
    }

    const memberAttributes = calculateEntryTotals(
      cleanAttributes($.extend(item, {
        amount: attributes.amount,
        user_id: gon.application.current_user.id,
        discount_value: entry.discount_type === 'percent' && !item.discount_disabled
          ? entry.discount_value
          : 0,
        discount_type: entry.discount_type,
      }))
    )
    const html = memberTemplate.railsTemplate('members').html

    if (entryHasStatus(item)) {
      html.find('.state .hidden').removeClass('hidden')
    }

    addNewMemberStateSelect(html.find('.entry_members_state select'))
    memberList.append(html)

    const member = html.first().nestedFieldSet()
    member.set(memberAttributes)

    entryType.entry_type_consumables.forEach(function (etc) {
      addMemberConsumable(etc.consumable, {
        parent: member,
        skipParentInit: true,
        amount: memberAmount * etc.amount,
      })
    })

    member.init()
    updateIndexes()
    memberSchema[member.getIndex()] = 1
    member.hideContent()
    postRenderRootElement(member)
    triggerListChanged()
    drawEntryMeasureUnits(html[0], entryType.measure_unit)
    updateTooltips(html, entryType)
  }

  const addConsumable = function (entryType) {
    const html = consumableTemplate.railsTemplate('consumables').html
    consumableList.append(html)

    const consumable = html.nestedFieldSet()
    const consumableAttributes = generateConsumableAttributes(entryType, {
      amount: attributes.amount,
    })

    consumable.set(consumableAttributes)
    consumable.redraw()
    postRenderRootElement(consumable)
    updateIndexes()
    triggerListChanged()
    drawEntryMeasureUnits(html[0], entryType.measure_unit)
    updateTooltips(html, entryType)
  }

  /**
   * @param {HTMLElement} row - Внутри какого элемента обновлять тултипы
   * @param {object} entryType - Данные энтри тайпа
   */
  function updateTooltips (row, entryType) {
    row.find('.consumable_title input').attr('title', entryType.title)
    row.find('.entry_member_title input').attr('title', entryType.title)
    row.find('.measure-unit').attr('title', entryType.measure_unit.title)

    Utils.updateTooltipsInContainer(row[0])
  }

  function generateConsumableAttributes (entryType, attrs) {
    return cleanAttributes(
      $.extend(
        buildItem(entryType),
        {
          price: 0,
          final_price: 0,
          sum: 0,
          final_sum: 0,
          consumable: true,
        },
        attrs)
    )
  }

  function addMemberConsumable (entryType, opts) {
    const member = opts.parent
    const html = member.getConsumableTemplate().railsTemplate('consumables').html
    member.appendConsumableHtml(html)
    const consumable = html.nestedFieldSet()
    const consumableAttributes = generateConsumableAttributes(entryType, {
      amount: opts.amount,
    })
    consumable.set(consumableAttributes)
    if (!opts.skipParentInit) member.init()
    consumable.redraw()
    updateIndexes()
    triggerListChanged()
    drawEntryMeasureUnits(html[0], entryType.measure_unit)
    updateTooltips(html, entryType)
  }

  // almost useless, amount checking performs only with order creation
  function openHiddenErrors (e, data) {
    const memberErrors = data.errors.members_attributes
    if (!memberErrors) return
    const members = getMembers()

    Object.keys(memberErrors).forEach(function (memberIndex) {
      const member = members[parseInt(memberIndex)]
      if (!member) return
      member.showContent()
    })
  }

  //
  // PubSub subscriptions
  //

  PubSub.on(msgPrefix + 'askAddElement', function (msg, data) {
    const selectorRow = data.selector.closest('tr')
    const entry = selectorRow.data('entry')
    const type = selectorRow.data('type')
    const item = $.extend({}, data.item)

    if (entry) {
      addMemberConsumable(item, {
        parent: entry,
        amount: parseInt(entry.get('amount')),
      })
    } else if (type === 'consumables') {
      addConsumable(item)
    } else if (type === 'members') {
      addMember(item, data.entry)
    }
  })

  PubSub.on(msgPrefix + 'askSetAttributes', function (msg, data) {
    attributes = data.attributes
    updateList()
  })

  PubSub.on('page.form.' + params.model + '.setNew', init)
  PubSub.on('page.form.' + params.model + '.setEdit', init)
  PubSub.on('page.form.' + params.model + '.submitError', openHiddenErrors)

  //
  // events
  //

  /**
   * Количество
   */
  tables.on('keyup change mouseup', '.f-nested-amount', function () {
    const row = $(this).closest('tr').nestedFieldSet()
    if (row.getName() === 'consumables') {
      // can be standalone consumable
      if (row.data('entry')) row.data('entry').updateSchema()
    }
    if (row.getName() === 'members') {
      if (this.readOnly) {
        Notificator.info(t('already_biled_this_service'))
      }
    }

    const memberAttributes = calculateEntryTotals(cloneDeep(row.get()))
    row.set(memberAttributes)
    row.updateContentItems()
    triggerListChanged()
  })

  tables.on('click', '.toggle-content-button', function () {
    const row = $(this).closest('tr').nestedFieldSet()
    row.toggleContent()
  })

  tables.on('click', '.f-nested-protocol', function (e) {
    e.preventDefault()
    const row = $(this).closest('tr').nestedFieldSet()
    row.openProtocolModal()
  })

  tables.on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    const row = $(this).closest('tr').nestedFieldSet()
    row.gracefulDestroy()
    updateIndexes()
    triggerListChanged()
  })

  memberList.on('input', '.f-nested-amount', function () {
    const newValue = $(this).val()
    const currentInputId = $(this).attr('id')
    const idNumber = currentInputId.match(/\d+/)[0]

    const linkedInput = $('#entry_members_attributes_' + idNumber + '_amount.f-member-amount')
    linkedInput.val(newValue)
  })

  return {}
}

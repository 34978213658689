import { DOCUMENT_TARGET } from '@/forms/const'
import { debounce } from 'lodash'
import { createVueApp } from '@/vue_components/create_vue_app'
import ModalWindowInputDocumentDates from '@/vue_components/modal_windows/document/ModalWindowInputDocumentDates.vue'
import ModalWindowSelectLegalRepresentative
  from '@/vue_components/modal_windows/legal_representative/ModalWindowSelectLegalRepresentative.vue'

window.DocumentForm = (function () {
  let init
  let _create

  let _handlerClickType
  let _handlerClickTypeMedicalRecord
  let _handlerFixedDocType
  let _handlerFixedDocTypeCustomLink
  let options

  const _defaults = {
    client_id: null,
    company_id: null,
    onSuccess: null,
    documents: [],
  }

  let $categoryList
  let $modal

  function setDocumentTarget (options) {
    const mapping = {
      client_id: DOCUMENT_TARGET.CLIENT,
      company_id: DOCUMENT_TARGET.COMPANY,
    }
    const targetKey = Object.keys(mapping).find((key) => options[key])

    if (!targetKey) {
      throw new Error('incorrect client_id or company_id in document form')
    }

    options.documentTarget = mapping[targetKey]
  }

  init = function (params) {
    $categoryList = $('#documents-category')
    $modal = $('#documents-modal')
    const $categories = $('.categories-list-spin')
    const $fixedDocs = $('#fixed_docs')
    options = $.extend({}, _defaults, params)

    setDocumentTarget(options)

    if (options.documentTarget === DOCUMENT_TARGET.CLIENT) {
      if (!Array.isArray(options.clientLegalRepresentatives)) {
        throw new Error('incorrect clientLegalRepresentatives in document form')
      }

      if (!options.clientLegalRepresentativesModalSelector) {
        throw new Error('missing legalRepresentativesModalSelector')
      }

      if (!options.documentDatesModalSelector) {
        throw new Error('missing documentDatesModalSelector')
      }
    }

    if (options.clientLegalRepresentativesModalSelector) {
      createVueApp({
        el: options.clientLegalRepresentativesModalSelector,
        name: 'ModalWindowSelectLegalRepresentativeApp',
        render: (h) => h(ModalWindowSelectLegalRepresentative, {
          props: {
            legalRepresentatives: options.clientLegalRepresentatives,
          },
        }),
      })
    }

    createVueApp({
      el: options.documentDatesModalSelector,
      name: 'ModalWindowInputDocumentDatesApp',
      render: (h) => h(ModalWindowInputDocumentDates),
    })

    $('.document-type').on(
      'click',
      (options.source === 'medical_record_print')
        ? _handlerClickTypeMedicalRecord
        : _handlerClickType
    )

    $('.fixed-document-type').on('click', _handlerFixedDocType)

    $('.fixed-document-type-custom-link').on(
      'click',
      _handlerFixedDocTypeCustomLink
    )

    $('.fixed-document-type-vue').on('click', function () {
      const self = $(this)

      const url = self.data('url')
      const documentKind = self.data('document-kind')
      const action = self.data('action')
      const clientId = self.data('client-id')

      const route = `${Routes[url]()}/${documentKind}/${action}?clientId=${clientId}`

      Turbolinks.visit(route)
    })

    $('.checkbox-container').on('click', function (e) { e.stopPropagation() })

    $('#doc_species').on('change', (e) => {
      if ($(e.target).val() === 'fixed_documents') {
        $categories.find('.categories-x').categoriesx('uncheck')
        $categories.hide()
        $fixedDocs.show()

        return
      }

      $categories.show()
      $fixedDocs.hide()
    })
  }

  _create = debounce(function (typeId) {
    $.ajax({
      url: '/documents',
      method: 'POST',
      data: {
        legal_representative_id: options.legal_representative_id,
        customer_legal_entity_id: options.customer_legal_entity_id,
        customer_individual_id: options.customer_individual_id,
        document_type_id: typeId,
        client_id: options.client_id,
        company_id: options.company_id,
        order_id: options.order_id,
        dental_order_id: options.dental_order_id,
        unfinished: options.unfinished,
        finalization_token: options.finalization_token,
        date_start: options.date_start,
        date_end: options.date_end,
      },
    }).done(function (result) {
      if ($.isFunction(options.onSuccess)) {
        options.onSuccess(result)
      } else {
        if (options.source === 'show') {
          Turbolinks.visit('/documents/' + result.id)
        }
        if (options.source === 'createDentalOrderDoc') {
          $.ajax({
            url: Routes.dental_order_path(options.dental_order_id),
            method: 'PATCH',
            data: {
              dental_order: {
                document_id: result.id,
              },
            },
          }).success(function () {
            Turbolinks.visit('/documents/' + result.id)
          })
        }

        if (options.source === 'edit') {
          hide()
          $('#order_document_id')
            .find('option:empty')
            .first()
            .after($('<option></option>')
              .attr('value', result.id)
              .attr('selected', 'selected')
              .text(result.title + ' №' + result.number + ' от ' +
                moment(result.created_at).format('DD.MM.YYYY')
              )
            )

          Notificator.info(t('new_document_created'))
        }
      }
    }).fail(Utils.reportError('document_form:_create'))
  }, 1000)

  _handlerClickType = function () {
    const self = $(this)
    const docTypeId = self.data('id')
    _create(docTypeId)
  }

  _handlerClickTypeMedicalRecord = function () {
    const self = $(this)
    const docTypeId = self.data('id')
    Turbolinks.visit('/medical_records/' + options.medical_record_id +
      '/print_custom_title_page/' + docTypeId
    )
  }

  _handlerFixedDocType = function () {
    const self = $(this)
    const path = self.data('path')
    const title = self.data('title')
    Turbolinks.visit(
      '/documents/new?fixed_doc_type=true&client_id=' +
      options.client_id + '&path=' + path + '&title=' + title
    )
  }

  _handlerFixedDocTypeCustomLink = function () {
    const self = $(this)
    const url = self.data('url')
    const id = self.data('id')

    Turbolinks.visit(Routes[url](id))
  }

  const _loadCategories = () => {
    const groupForm = FormFactory.build('document_type_members_editing', {
      list: $categoryList,
      clientLegalRepresentatives: options.clientLegalRepresentatives,
    })

    const template = FormFactory.build('document_type', { categoryList: $categoryList, origin: options.origin })
    const actions = options.actions || {
      createAndPrint: t('create_and_print'),
      createItems: t('create'),
    }

    function createDocument (id) {
      $categoryList.categoriesx('startSpin')
      _create(id)
      $categoryList.categoriesx('stopSpin')
    }

    const clientIsKid = () => moment().diff(gon.specific.client.birthdate, 'years') < 18

    const haveLegalRepresentativesVar = (item) => Utils.includeLegalRepresentativesVar(item.data)

    const haveLegalRepresentativesOrClientVar = (item) =>
      Utils.includeLegalRepresentativesOrClientVar(item.data)

    const haveDocumentDatesVar = (item) =>
      Utils.includeDocumentDatesVar(item.data)

    const checkAndFillDocumentDates = (item) => {
      return new Promise((resolve, reject) => {
        if (!haveDocumentDatesVar(item)) {
          resolve()

          return
        }

        Services.pubSub.subscribe(
          'MODAL_WINDOW_INPUT_DOCUMENT_DATES.DOCUMENT_DATES',
          function (documentDates) {
            Services.pubSub.reset('MODAL_WINDOW_INPUT_DOCUMENT_DATES.DOCUMENT_DATES')
            Services.pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE', false)

            options.date_start = documentDates.dateStart
            options.date_end = documentDates.dateEnd
            resolve()
          })

        Services.pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE', true)
      })
    }

    const checkLegalRepresentativesAndCreateDocument = (item) => {
      if (options.clientLegalRepresentatives.length > 1) {
        const shouldListenForLegalRep = haveLegalRepresentativesVar(item) ||
          (haveLegalRepresentativesOrClientVar(item) && clientIsKid())

        if (!shouldListenForLegalRep) {
          createDocument(item.id)

          return
        }

        Services.pubSub.subscribe(
          'MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_ID',
          function (legalRepresentativeId) {
            Services.pubSub.reset('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_ID')
            Services.pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE', false)

            options.legal_representative_id = legalRepresentativeId

            if (options.finishedLegalRepresentativeId) {
              options.finishedLegalRepresentativeId.val(legalRepresentativeId)
            }
            createDocument(item.id)
          })

        Services.pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE', true)

        return
      }

      options.legal_representative_id =
          options.clientLegalRepresentatives.length
            ? options.clientLegalRepresentatives[0].id
            : undefined

      if (options.finishedLegalRepresentativeId) {
        options.finishedLegalRepresentativeId.val(options.legal_representative_id)
      }

      createDocument(item.id)
    }

    $categoryList.categoriesx({
      url: Routes.document_types_categories_path(),
      category_type: 14,
      editable: options.itemEditable,
      groupEdit: options.groupEditable, //set from init
      inModal: options.inModal,
      groupEditOlnly: options.groupEditOlnly,
      groupForm,
      itemForm: template,
      responsive: { offset: 205 },
      redirectDestination: options.redirectDestination,
      order_id: options.order_id,
      actions,
      complete: options.complete,
      groupActionsType: 'buttons',
      itemUrl: Routes.document_type_path,
      onClick (item) {
        checkAndFillDocumentDates(item).then(() => {
          if (options.documentTarget === DOCUMENT_TARGET.COMPANY) {
            createDocument(item.id)
          } else {
            checkLegalRepresentativesAndCreateDocument(item)
          }
        })
      },
      onItemEdit: _.noop,
      onItemRemove: _.noop,
    })
  }

  function show ({ clientId, customerClientId, companyId } = {}) {
    if (clientId) {
      options.client_id = clientId
      options.customer_individual_id = customerClientId
      options.company_id = null
    }

    if (companyId) {
      options.company_id = companyId
    }

    $modal.modal('show')
    _loadCategories()
  }

  function hide () {
    $modal.modal('hide')
  }

  return {
    init,
    show,
    hide,
  }
})()

import { drawDentalOrderMeasureUnit } from '@/vue_apps/MeasureUnits/logic/drawDentalOrderMeasureUnit'

FormFactory.dentalOrderEntryList = function (params) {
  const msgPrefix = `page.form.${params.model}.dentalOrderEntryList.`

  const container = params.dentalOrderEntryList.container
  let attributes = {}
  const entriesTemplate = $('._template_dental_order_entries')
  const round = Utils.moneyRound

  //
  // core funcs
  //

  const togglePriceFields = function () {
    gon.application.settings.dental_order_price
      ? $('.dental-order-price').hide()
      : $('.dental-order-price').show()
  }

  const init = function () {
    container.find('.entry_row').each(function (i, e) {
      const row = $(e).nestedFieldSet()
      row.init()
      row.hideContent()
    })
    togglePriceFields()
    triggerListChanged()
  }

  let updateIndexesTimeout
  const updateIndexes = function () {
    if (updateIndexesTimeout) clearTimeout(updateIndexesTimeout)

    // massive complex addition calls updateIndex too many times and freezes ui
    updateIndexesTimeout = setTimeout(function () {
      container[0].querySelectorAll('.entry_row').forEach(function (e, i) {
        const row = $(e).nestedFieldSet()
        row.setIndex(i)
        row.init()
      })
    }, 10)
  }

  const buildItem = function (proto) {
    return {
      entry_type_id: proto.id,
      amount: proto.amount,
      price: proto.price,
      title: proto.title,
      measure_unit: proto.measure_unit,
    }
  }

  let triggerListChangedDelay = null
  const triggerListChanged = function () {
    if (triggerListChangedDelay) clearTimeout(triggerListChangedDelay)
    triggerListChangedDelay = setTimeout(function () {
      const result = { sum: 0 }

      container.find('.entry_row').each(function (i, e) {
        const row = $(e).nestedFieldSet()

        if (row.isDeleted()) return

        result.sum += parseFloat(row.get('sum'))
      })

      result.sum = round(result.sum)

      PubSub.emit('page.form.' + params.model + '.updateComponents')
      PubSub.emit(msgPrefix + 'listChanged', result)
    }, 10)
  }

  const addEntry = function (entryType) {
    const entryProto = $.extend({}, entryType, { amount: entryType.amount || 1 })
    const entryAttributes = _.omitBy(buildItem(entryProto), _.isUndefined)
    const entryHtml = entriesTemplate.railsTemplate('dental_order_entries').html
    const entryRow = entryHtml.first().nestedFieldSet()

    entryRow.set(entryAttributes)
    container.append(entryHtml)

    togglePriceFields()
    drawDentalOrderMeasureUnit(entryRow.html()[0], entryAttributes.measure_unit)
    Utils.updateTooltipsInstances()

    entryRow.init()
    entryRow.recalculate()
    updateIndexes()
    triggerListChanged()
  }

  const setAttributes = function (opts) {
    container.find('.f-order-entry').each(function (i, e) {
      const row = $(e).nestedFieldSet()
      const newAttrs = _.clone(attributes)

      row.set(newAttrs)
    })

    container.find('.entry_row').each(function (i, e) {
      $(e).nestedFieldSet().recalculate()
    })

    triggerListChanged()
  }

  const openHiddenErrors = function (e, data) {
    const entriesErrors = data.errors.entries_attributes
    if (!entriesErrors) return

    const entries = container.find('.entry_row').toArray().map(function (e) {
      return $(e).nestedFieldSet()
    })

    Object.keys(entriesErrors).forEach(function (entryIndex) {
      const entry = entries[parseInt(entryIndex)]
      if (!entry) return

      const entryErrors = entriesErrors[entryIndex]
      const membersErrors = entryErrors.members_attributes

      if (membersErrors) {
        Object.keys(membersErrors).forEach(function (memberIndex) {
          const member = entry.getDataArray('members')[parseInt(memberIndex)]
          const memberErrors = membersErrors[memberIndex]
          const consumableErrors = memberErrors.consumables_attributes
          if (member && consumableErrors) member.showContent()
        })
      } else {
        const consumableErrors = entryErrors.consumables_attributes
        if (consumableErrors) entry.showContent()
      }
    })
  }

  //
  // PubSub subscriptions
  //

  PubSub.on(msgPrefix + 'askAddEntry', function (msg, data) {
    if (!data.selector) return addEntry(data.item)

    addEntry(data.item)
  })

  PubSub.on(msgPrefix + 'askSetAttributes', function (msg, data) {
    attributes = $.extend(
      attributes,
      _.omitBy($.extend({}, data.attributes), _.isUndefined)
    )
    setAttributes({ force: false })
  })

  PubSub.on(msgPrefix + 'askForceAttributes', function (msg, data) {
    attributes = $.extend(
      attributes,
      _.omitBy($.extend({}, data.attributes), _.isUndefined)
    )
    setAttributes({ force: true })
  })

  PubSub.on('page.form.' + params.model + '.setNew', init)
  PubSub.on('page.form.' + params.model + '.setEdit', init)
  PubSub.on('page.form.' + params.model + '.submitError', openHiddenErrors)

  //
  // events
  //

  const recalculateSelector =
    '.f-price' +
    '.f-order-entry-amount, .f-order-member-amount, ' +
    '.f-discount-value'

  container.on('keyup change mouseup', recalculateSelector, function () {
    const row = $(this).closest('tr').nestedFieldSet()
    row.recalculate()
    triggerListChanged()
  })

  container.on('keyup change mouseup', '.f-order-entry-price, .f-order-entry-amount', function () {
    const row = $(this).closest('tr').nestedFieldSet()
    const price = parseFloat(row.get('price'))
    if (price < 0) {
      row.set('price', Math.abs(price))
    }
    row.recalculate()
    triggerListChanged()
  })

  container.on('click', '.toggle-content-button', function () {
    const row = $(this).closest('tr').nestedFieldSet()
    row.toggleContent()
  })

  container.on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    const row = $(this).closest('tr').nestedFieldSet()
    const entry = row.data('dental_order_entry')
    row.gracefulDestroy()
    updateIndexes()
    if (entry) entry.redraw()
    triggerListChanged()
  })

  return {}
}

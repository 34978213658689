import { EMeasureUnitKinds } from '@/_declarations/enums/EMeasureUnitKinds'

export const drawDentalOrderMeasureUnit =
  (rowHTML: HTMLElement, measureUnit: { id: number; title: string; short_title: string; kind: EMeasureUnitKinds }) => {
    if (!rowHTML || !measureUnit) { return }

    const measureUnitElement = rowHTML.querySelector('.measure-unit') as HTMLElement
    if (!measureUnitElement) { return }

    measureUnitElement.innerText = measureUnit.short_title ?? ''
    measureUnitElement.setAttribute('data-tooltip', measureUnit.title ?? '')
  }

import { drawNDS } from '@/forms/components/order/NDS/drawNDS'
import { updateCommentTooltip } from '@/forms/components/entry/updateCommentTooltip'

FormFactory.orderEntryConsumable = function () {
  $.NestedFieldSet.register('f-order-nested-consumable', {
    name: 'consumables',
    render () {
      const entry = this.data('entry')
      const lockedOrder = gon.specific.order_locked
      const banPaidOrder = gon.application.ban_paid_order_attributes
      if (!entry) return

      this.updateDiscountTypeBtns(this.getDiscountType())

      this.setFieldsLock(
        banPaidOrder && lockedOrder,
        'referral_id',
        'user_id'
      )

      this.setFieldsLock(
        (entry && entry.get('amount', 'int') > 1) || lockedOrder,
        'amount'
      )
      this.updateStoreVisibility()
      if (this.data('locked_from_billing_changes')) this.lockFromBillingChanges()

      if (this.html && this.html().length) {
        Utils.updateTooltips(this.html()[0])
      }

      if (entry?.html && entry?.html().length) {
        Utils.updateTooltipsInstances(entry.html()[0])
      }

      drawNDS(this)
    },

    extend: $.extend(FormFactory.orderEntryCommon(), {
      init () {
        this.redraw()
        this._initDiscountSelector()
        updateCommentTooltip(this.html())
      },

      gracefulDestroy () {
        this.destroy()
      },

      lockFromBillingChanges () {
        this._lockFromBillingChanges()
      },

      recalculate: _.noop,
      showDirectChildren: _.noop,
      hideDirectChildren: _.noop,
      updateContentItems: _.noop,
      updateChildIndexes: _.noop,
    }),
  })

  return {}
}

import { IMeasureUnitRaw } from '@/_declarations/IMeasureUnit'

/**
 * Рендер единицы измерения при добавлении member или consumable на странице
 * редактирования энтри.
 */
export const drawEntryMeasureUnits = (rowHTML: HTMLElement, measureUnit: IMeasureUnitRaw) => {
  if (!rowHTML && !measureUnit) return

  const measureUnitCell = rowHTML.querySelector('.measure-unit')

  const measureUnitShortTitleElement = measureUnitCell.querySelector('.f-measure-unit-short-title') as HTMLInputElement
  if (!measureUnitShortTitleElement) { return }

  measureUnitShortTitleElement.innerText = measureUnit.short_title

  const measureUnitIdElement = measureUnitCell.querySelector('input.f-measure-unit-id') as HTMLInputElement
  if (!measureUnitIdElement) { return }

  measureUnitIdElement.value = String(measureUnit.id)
}
